export default
`
#####      **Pros** *&* Cons

###### Made with [React](https://facebook.github.io/react/), [yarn](https://yarnpkg.com/), and [spectre.css](https://picturepan2.github.io/spectre/).

Copyrights (2017) for Krzysztof Wielogórski.


${"`"}~ Always make choices best for you ~${"`"}
`;
